.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #fafafa;
	background-color: #fafaff;
	margin: 0 auto 1em auto;
}
.header__banner {
	flex: 1;
	font-size: 1.5em;
}
.header__link {
	color: #337;
	text-decoration: none;
}
.header__account {
	text-align: right;
	flex: 0.1;
}