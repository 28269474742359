.LoginPanel {

}
.LoginPanel__field {
	display: block;
	margin: .6em 0;
}
.LoginPanel__label {
	display: inline-block;
	width: 6em;
}
.LoginPanel__input {
	display: inline-block;
	min-width: 15em;
	font-size: large;
}
.LoginPanel__status {
	margin: .5em 0;
}
.LoginPanel__icon {
	margin-right: .5em;
	color: #900;
}
.LoginPanel__button {
	font-size: large;
}