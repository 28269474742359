@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?e8ityg');
    src:    url('../fonts/icomoon.eot?e8ityg#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?e8ityg') format('truetype'),
        url('../fonts/icomoon.woff?e8ityg') format('woff'),
        url('../fonts/icomoon.svg?e8ityg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Insert the right icon! */
    content: attr(data-icon);
}