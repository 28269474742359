.GridView {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.GridView--leftAlign {
	justify-content: flex-start;
}

@media (max-width: @small-screen-width) {
	.GridView {
		justify-content: flex-start;
	}
}
