.PhotoInfo {
	font-size: 100%;
}

.PhotoInfo__heading {
	font-size: large;
}

.PhotoInfo__field {
}

.PhotoInfo__fieldIcon {
	margin-right: 0.3em;
}

.PhotoInfo__fieldName {
	display: inline-block;
	width: 6.5em;
}
.PhotoInfo__fieldName:after {
	content: ':'
}

.PhotoInfo__fieldValue {
	font-size: 100%;
	display: inline-block;
	max-width: 80%;
	overflow: auto;
	text-overflow: ellipsis;
}

// Hide field name on small screens
@media (max-width: @small-screen-width) {
	.PhotoInfo__fieldName {
		display: none;
	}
	.PhotoInfo__fieldName:after {
		display: none;
	}
	.PhotoInfo__fieldValue {
		max-width: 90%;
	}
}