.HeadingBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: .5em 0;
}

.HeadingBar__heading {
	font-size: 150%;
}

.HeadingBar__options {
	max-width: 150px;
}