.Breadcrumbs {
	list-style: none;
	margin: 0;
	padding: 0;
}

.Breadcrumbs__item {
	display: inline-block;
}

.Breadcrumbs__item + .Breadcrumbs__item:before {
	content: ' > ';	
	padding: 0 0.5em;
	font-size: 70%;
	color: #999;
	vertical-align: 10%;
}

.Breadcrumbs__content--link {
	.link;
	text-decoration: underline;
}