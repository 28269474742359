.PhotoDetails {
	width: 100%;
	display: block;
	position: relative;
}
.PhotoDetails__image {
	display: block;
	margin: 0 auto;
	width: 100%;
}
.PhotoDetails__loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.4;
	display: flex;
	justify-content: center;
}
.PhotoDetails__message {
	margin-top: 4%;
}