/* Reusable */
@import "ui/variables.less";
@import "ui/icons.less";

.link() {
	color: @link-colour;
	cursor: pointer;
}

/* Components */
@import "ui/components/Account.less";
@import "ui/components/AccountInfo.less";
@import "ui/components/Breadcrumbs.less";
@import "ui/components/DateView.less";
@import "ui/components/GridView.less";
@import "ui/components/Footer.less";
@import "ui/components/HeadingBar.less";
@import "ui/components/Header.less";
@import "ui/components/Icon.less";
@import "ui/components/LoginPanel.less";
@import "ui/components/Page.less";
@import "ui/components/PhotoNavBar.less";
@import "ui/components/PhotoDetails.less";
@import "ui/components/PhotoInfo.less";
@import "ui/components/Shared.less";
@import "ui/components/Thumbnail.less";
@import "ui/components/ViewOptions.less";

/* Top level */
@import "ui/app.less";