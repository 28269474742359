.PhotoNavBar {
	margin: .6em auto;
	max-width: 300px;
	display: flex;
	justify-content: space-between;
}

.PhotoNavBar__link() {
	display: inline;
	margin: 0 1em;
	font-size: large;
	color: @disabled-colour;
}

.PhotoNavBar__prev {
	.PhotoNavBar__link;
}
.PhotoNavBar__prev--link {
	.link;
}

.PhotoNavBar__next {
	.PhotoNavBar__link;
}
.PhotoNavBar__next--link {
	.link;
}

.PhotoNavBar__curr {
	display: inline;
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
}