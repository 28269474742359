@thumbnail-height: 140px;

.Thumbnail {
	margin: 3px;
}
.Thumbnail__image {
	max-height: @thumbnail-height;
	border-radius: 5px;
	border: 2px solid #dde;
}
.Thumbnail__image--selected {
	border-color: red;
}

@media (max-width: @small-screen-width) {
	.Thumbnail__image {
		max-height: @thumbnail-height / 3;
	}
}

@media (min-width: @small-screen-width) and (max-width: @medium-screen-width) {
	.Thumbnail__image {
		max-height: @thumbnail-height / 1.5;
	}
}